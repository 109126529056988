import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBlogs } from "../../api/blogs";
import { MoonLoader } from "react-spinners";
import BlogCard from "../Blogs/BlogCard"
import { useTranslation } from "react-i18next";

function Guides() {
  const { t } = useTranslation(null, { keyPrefix: "homepage.guidesSection" })
  const [guides, setGuides] = useState(undefined)
  const [loading, setLoading] = useState(false); // State variable for loading spinner


  useEffect(() => {
    getBlogs(1, 3)
      .then(results => {
        const [blogs, total_pages] = results
        setGuides(blogs)
        setLoading(false); // Set loading state to false after data is fetched


      })
      .catch(error => {
        console.error("Error:", error);
        setLoading(false); // Set loading state to false if there's an error

      });
  }, [])

  return (
    <div className="w-custom mx-auto rounded-md my-8 md:mt-12">
      <div className="flex justify-between items-end max-sm:px-4">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
          {t("guidesTitle")}
        </h2>
        <Link to={"blogs"}>
          <p className="text-lg md:text-xl font-medium underline cursor-pointer">
            {t("seeAllButton")}
          </p>
        </Link>
      </div>
      {guides && (
        <div className="grid mt-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-10 2xl:py-5">
          {guides.map((item, index) => (
            <div key={index} className="flex justify-center h-full w-full">
              <BlogCard
                key={index}
                translated_titles={item.translated_titles}
                image={item.image_url}
                date={item.updated_at}
                permalink={item.permalink}

              />

            </div>
          ))}
        </div>
      )}

      {/* Render Spinner while blogs are being fetched  */}
      {loading && (

        <div>
          <MoonLoader color="red" speedMultiplier={1.1} />

        </div>

      )}


      {/* Fallback UI if blogs are still undefined after the fetch  */}
      {!loading && !guides && (
        <div>
          Something went wrong while fetching blogs.
        </div>
      )}
    </div>
  );
}

export default Guides;
