import React from "react";
import useNavigateCustom from "../hooks/useNavigateCustom";
import { useEffect } from "react";

function NotFound404() {
  const navigate = useNavigateCustom();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("");
    }, 5000);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="w-screen min-h-[70svh] flex justify-center items-center flex-col">
      <h1 className="text-6xl font-bold px-4 text-center">Page not found.</h1>
      <p className="mt-2 text-2xl px-4 text-center">Redirecting to Homepage....</p>
      <button className="button !px-8 !py-4 mt-4" onClick={() => navigate("")}>
        Home
      </button>
    </div>
  );
}

export default NotFound404;
 