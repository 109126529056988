import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL, // Your base URL
  timeout: 10000, // Adjust the timeout as needed
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // You can add common headers here
  },
});

export default instance;
