import { formatDate } from "../../utils/dateFormatter";
import { Link, useLocation } from "react-router-dom";
import LazyImage from "../LazyImage";



const BlogCard = ({
    image,
    translated_titles,
    date,
    permalink
}) => {

    const { pathname } = useLocation();
    const lng = localStorage.getItem("lng") || "en"


    // Define a function to generate the correct permalink based on the current route
    const generatePermalink = () => {
        if (pathname.includes("/blogs")) {
            // If the current route already includes "/blogs", use the permalink directly
            return permalink.substring(0);
        } else {
            // If not, prepend "/blogs" to the permalink
            return `blogs/${permalink}`;
        }
    };

    // Generate the permalink based on the current route
    const adjustedPermalink = generatePermalink();


    return (
        <Link
            to={adjustedPermalink} // Fixed the condition and added missing parenthesis
            className="flex flex-col items-start justify-between overflow-hidden rounded-xl bg-white w-3/4 lg:w-full h-96 drop-shadow-md ">
            <div>
                <LazyImage loading="lazy" src={image} alt="truck" className="w-full object-cover h-64 rounded-t-xl" />
                <p className="p-2 font-bold text-xl line-clamp-3">
                    {translated_titles[lng] || translated_titles.en}
                </p>
            </div>
            <p className="pb-3 pl-2 font-medium text-sm ">{formatDate(date)}</p>
        </Link>
    )
}
export default BlogCard