import LanguageAssigner from "./utils/LanguageAssigner";
import NotFound404 from "./components/404";

import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import NavigateToHome from "./utils/NavigateToHome";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify"


function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <AppContent />
        <Footer />
      </div>
    </Router>
  );
}


function AppContent() {

  const location = useLocation();
  const navigate = useNavigate()
  const isOrderStatusRoute = location.pathname.startsWith("/order/success" || "/order/failure")

  // Programatically injecting language string in URL for redirect url from stripe
  useEffect(() => {
    if (isOrderStatusRoute) {
      const language = localStorage.getItem("lng")
      const newPath = `/${language}${location.pathname}${location.search}`;
      navigate(newPath)
    }
  }, [location, isOrderStatusRoute, navigate])

  return (
    <main className="flex-grow">
      <Routes>
        <Route path="/" element={<NavigateToHome />} />
        <Route path="/:lng/*" element={<LanguageAssigner />} />
        <Route path="/*" element={<NotFound404 />} />
      </Routes>
      <ToastContainer />
    </main>
  )
}

export default App;