import React from "react";
import mountainImage from "../../assets/images/homePageInfo.webp";
import tollRoad from "../../assets/svgs/tollRoad.svg";
import howItWorks from "../../assets/svgs/howItWorks.svg";
import faqs from "../../assets/svgs/faqs.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import LazyImage from "../LazyImage"

function VigInfoSection() {
  const { t } = useTranslation(null, { keyPrefix: "homepage.infoSection" });

  const boxesData = [
    {
      image: tollRoad,
      label: t("box1"),
      link: "toll-roads"
    },
    {
      image: howItWorks,
      label: t("box2"),
      link: "terms-of-use"
    },
    {
      image: faqs,
      label: t("box3"),
      link: "faqs"
    },
  ]

  return (
    <div className="bg-[#FBFBEA] py-8">

      <div className="w-custom mx-auto max-md:px-6">
        {/* INFO TOP  */}
        <div>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
            {t("heading")}
          </h2>

          <LazyImage
            src={mountainImage}
            alt={"Mountains"}
            className={"my-4 h-[356px] w-full object-cover rounded-xl"}
          />


          <ul className="pb-4 text-lg">
            {t("paragraphs", { returnObjects: true }).map((content, index) => (
              <li key={index} className=" py-2">
                {content}
              </li>
            ))}
          </ul>
        </div>


        {/* INFO BOTTOM  */}
        <div className="flex w-full items-center justify-between">
          {boxesData.map((item, index) => (
            <Link key={index} to={item.link} className=" bg-white font-light flex flex-col items-center justify-center max-md:text-sm text-center p-2 gap-2 max-md:w-[30%] w-1/4 h-40 rounded-lg">
              <LazyImage
                src={item.image}
                alt={"howitworks"}
                className={`w-12 h-12 md:w-16 md:h-16 p-${index}`}
              />
              {item.label}
            </Link>
          ))}
        </div>



      </div>
    </div>
  );
}

export default VigInfoSection;
