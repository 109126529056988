import { useTranslation } from "react-i18next"
import TollRoadsMap from "../assets/images/tollRoadsImage.webp"
import LazyImage from "../components/LazyImage";
import MetaTags from '../components/MetaTags';

const TollRoads = () => {
    const { t } = useTranslation(null, { keyPrefix: "tollRoads" });

    return (
        <section>
            <MetaTags title={t("meta_title")} description={t("meta_description")} />

            {/* <Banner bgImage={bannerImage} /> */}
            <h1 className="uppercase text-2xl border-b-4 border-secondary font-bold md:text-3xl lg:text-4xl text-center bg-light-bg p-4 ">
                {t("title")}
            </h1>


            <div className="px-8 pb-8 my-8 w-custom mx-auto">
                <p className="font-medium text-lg text-justify w-full">
                    {t("heading1")}
                </p>

                <div className="w-full h-full mt-5">
                    <LazyImage
                        src={TollRoadsMap}
                        className=""
                        alt="toll roads map"
                    />
                </div>

                <div className="text-lg bg-secondary-bg px-10 py-6 mt-10 flex flex-col items-start">
                    <p className="">
                        {t("infoSection.para1")}
                    </p>

                    <ol className="gap-5 list-decimal px-5">
                        <br />
                        {t("infoSection.reasons", { returnObjects: true }).map((item, index) => (
                            <li key={index}>
                                <h4 className="font-bold inline">
                                    {item.heading}
                                </h4>

                                &nbsp;{item.content}
                            </li>
                        ))}

                    </ol>

                </div>

            </div>

        </section>
    )
}
export default TollRoads