import React, { useState } from "react";
import { languages } from "../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { changeLanguage } from "i18next";
import useNavigateCustom from "../hooks/useNavigateCustom";
import LazyImage from "./LazyImage";





function LanguageSelect({ setShowSidebar = () => { } }) {
  const navigate = useNavigateCustom();
  const location = useLocation();
  const nativeNavigate = useNavigate()
  const lng = location.pathname.split("/")[1];
  const options = [
    ...languages.map((e) => ({
      value: e.value,
      label: (
        <div className="flex gap-2 items-center">
          <LazyImage src={e.flag} alt="flag" className="h-4 w-8" />
          <span>{e.value.toUpperCase()}</span>
        </div>
      ),
    })),
  ];

  const [selectedOption, setSelectedOption] = useState(
    options.find((e) => e.value === lng) || options[0]
  );



  const handleLanguageChange = (e) => {
    changeLanguage(e.value);
    localStorage.setItem("lng", e.value);
    setSelectedOption(e);
    setShowSidebar(true);
    document.body.classList.toggle("overflow-hidden", false);


    const currentPath = location.pathname;
    const newLang = e.value;

    // Construct the new path by replacing the current language segment with the new language
    const newPath = currentPath.replace(`/${currentPath.split("/")[1]}`, `/${newLang}`);
    // navigate(newPath);
    nativeNavigate(newPath)


  };








  return (
    <Select
      defaultValue={selectedOption}
      onChange={handleLanguageChange}
      options={options}

      styles={{
        indicatorSeparator: () => ({ display: "none" }),
        control: (provided, state) => ({
          ...provided,
          borderColor: "#01A655",
          boxShadow: null,
          ":hover": {
            cursor: "pointer"
          }

        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#01A655" : "#fff",
          ":hover": {
            backgroundColor: "#D9F1E5",
            cursor: "pointer",
            transition: "all",
            transitionDuration: "0.2s"
          }
        }),
      }}
      isSearchable={false}
    />
  );
}

export default LanguageSelect;
