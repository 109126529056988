import React from "react";
import VehiclesSection from "../components/homepage/VehiclesSection";
import Guides from "../components/homepage/Guides";
import VigInfoSection from "../components/homepage/VigInfoSection";
import MetaTags from '../components/MetaTags';
import { useTranslation } from "react-i18next";
import BannerNew from "../components/homepage/BannerNew"
import { useLocation } from "react-router-dom";
import banner from "../assets/images/new_hompage_bg.webp"
import HowItWorks from "../components/homepage/HowItWorks";
import HowItWorksInfoBoxes from "../components/homepage/HowItWorksInfoBoxes";



function HomeNew() {
  const { t } = useTranslation(null, { keyPrefix: "homepage" });
  const { pathname } = useLocation()


  // console.log(pathname)
  return (
    <div className="">
      <MetaTags title={t("meta_title")} description={t("meta_description")} />


      <div className="relative">
        <img
          src={banner}
          alt="banner"
          className="w-full h-full object-cover absolute inset-0  z-[-1] "
        />
        <BannerNew />
        <VehiclesSection />
      </div>

      <VigInfoSection />
      <Guides />

      <HowItWorks />

      <HowItWorksInfoBoxes />

    </div>
  );
}

export default HomeNew;
