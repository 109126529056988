import React from "react";
import useNavigateCustom from "../hooks/useNavigateCustom";

function UnderMaintenance() {
  const navigate = useNavigateCustom();
  return (
    <div className="w-screen min-h-[70svh] flex justify-center items-center flex-col">
      <h1 className="text-6xl font-bold px-4 text-center">Site Under Maintenance.</h1>
      <p className="mt-2 text-2xl px-4 text-center">Sorry, the website is currently under maintenance. We will be back soon.</p>
    </div>
  );
}

export default UnderMaintenance;
