import React, { useState, useEffect, useRef } from 'react';

const LazyImage = ({ src, alt, className }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imgRef = useRef(null);

    useEffect(() => {
        // Create an IntersectionObserver
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                // If the image is intersecting with the viewport
                if (entry.isIntersecting) {
                    // Mark the image as visible
                    setIsVisible(true);
                    // Stop observing once the image is visible
                    observer.unobserve(entry.target);
                }
            });
        });
        // Start observing the image element
        observer.observe(imgRef.current);

        // Cleanup function to stop observing when component unmounts
        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, []);

    return <img className={className} ref={imgRef} src={isVisible ? src : ''} alt={alt} />;
};

export default LazyImage;
