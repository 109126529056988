import React, { useEffect, useState } from "react";
import useNavigateCustom from "../../hooks/useNavigateCustom";
import { vehicles } from "../../utils/constants";
import CarImage from "../../assets/images/vehicles/carImg.webp"
import motorImage from "../../assets/images/vehicles/motorBike.webp"
import trailerImage from "../../assets/images/vehicles/trailerImage.webp"




const vehicleImages = [
  CarImage,
  trailerImage,
  motorImage,
]


function VehiclesSection() {
  const navigate = useNavigateCustom();
  const [vehiclesState, setvehiclesState] = useState(undefined)
  const language = localStorage.getItem("lng")

  // Loading vehicles only when language is set in the localstorage 
  useEffect(() => {
    setvehiclesState(vehicles)
  }, [language])



  return (
    <div className="w-custom md:bg-white mx-auto flex flex-col items-start md:items-center gap-5 md:pt-5 md:pb-8 px-5 lg:px-8 md:border-[6px] border-black border-opacity-5">
      <h2 className=" font-bold text-2xl lg:text-3xl">Select your vehicle type </h2>
      {language && vehiclesState && (
        <div className="flex flex-col md:flex-row items-center w-full gap-4">
          {vehiclesState.map((data, index) => (
            <div
              key={index}
              className="flex justify-between md:justify-normal md:flex-col items-center p-5 lg:p-8  bg-[#F9FFF9] border-[2px] border-gray-300 rounded-lg w-full md:w-1/3 md:h-[350px]"
            >
              <img
                src={vehicleImages[index]}
                alt="car"
                className="bg-white w-24 h-24 md:w-28 md:h-28"
              />

              <div className="flex flex-col items-end md:items-center w-full h-full  text-center">
                <div className="md:my-2">{data.name[language]}</div>
                <div className=" text-end md:text-center w-5/6 my-1 md:my-2 text-xs font-light md:text-sm">{data.subtitle[language]}</div>
                <button
                  className="button mt-auto w-6/12 md:w-full"
                  onClick={() => navigate(`checkout?vehicle=${data.name[language]}`)}
                >
                  BUY
                </button>
              </div>

            </div>
          ))}
        </div>
      )}

    </div>
  );
}

export default VehiclesSection;



