import React, { useEffect } from "react";
import useNavigateCustom from "../hooks/useNavigateCustom";
import LanguageSelect from "./LanguageSelect";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";


function NavOptions({
  selectedOption,
  setSelectedOption,
  hidden,
  setShowSidebar,
}) {


  const { t } = useTranslation(null, { keyPrefix: "appbar" })
  const navigate = useNavigateCustom();
  const location = useLocation()

  const navOptions = [
    {
      label: t("navLinks", { returnObjects: true })[0],
      link: "",
    }, {
      label: t("navLinks", { returnObjects: true })[1],
      link: "about-us",

    }, {
      label: t("navLinks", { returnObjects: true })[2],
      link: "blogs"
    }, {
      label: t("navLinks", { returnObjects: true })[3],
      link: "faqs"
    }, {
      label: t("navLinks", { returnObjects: true })[4],
      link: "contact-us"
    },
  ];



  useEffect(() => {
    let value = location.pathname.split("/")[2] || "";
    setSelectedOption(value);

  }, [location.pathname, setSelectedOption]);






  return (
    <>
      {/* NAV OPTIONS LEFT  */}
      <div
        className={`${hidden ? "hidden" : ""
          } lg:flex items-center gap-4 uppercase`}
      >
        {navOptions.map((item, index) => (
          <div
            key={index}
            className="my-5"
          >
            <Link
              to={`${localStorage.getItem("lng")}/${item.link}`}
              className="group font-medium relative px-4 py-2 cursor-pointer"
              onClick={(e) => {
                setSelectedOption(item.link)
                setShowSidebar((prev) => !prev)
                document.body.classList.toggle("overflow-hidden", false);
              }}
            >
              <span className="relative px-1 md:p-0">
                {item.label}

              </span>

              {/* BORDER ANIMATE ON HOVER EFFECT  */}
              <span className="absolute border border-black   bottom-0 left-0 w-0 opacity-0 md:group-hover:opacity-100 md:group-hover:w-full transition-width ease-in-out duration-300" />


              {/* BOTTOM BORDER FOR SELECTED LINK   */}
              {selectedOption?.toLowerCase() === item.link?.toLowerCase() && (
                <span className="absolute hidden md:flex w-full border border-black bottom-0 left-0 animate-width-animation" />
              )}

            </Link>
          </div>
        ))}
      </div>


      {/* NAV OPTONS RIGHT  */}
      <div
        className={`${hidden ? "hidden" : ""
          } lg:flex items-center mt-8 lg:mt-0`}
      >
        <div className="flex flex-col lg:flex-row gap-4 min-w-32">
          <button
            className="uppercase button min-w-32"
            onClick={() => {
              navigate("checkout")
              setShowSidebar((prev) => !prev)
              document.body.classList.toggle("overflow-hidden", false);

            }}
          >
            {t("buyButton")}
          </button>
          <LanguageSelect setShowSidebar={setShowSidebar} />
        </div>
      </div>
    </>

  );
}

export default NavOptions;
