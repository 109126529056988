import axiosInstance from "./axiosConfig"; // Import the axios instance

export function getBlogs(currentPage = 1, numOfBlogPerPage = 2) {
    return axiosInstance.get(`/list-blogs/?page=${currentPage}&page_size=${numOfBlogPerPage}`)
        .then(response => {
            // Assuming your API returns data in a format like response.data.results
            return [response.data.results, response.data.total_pages];
        })
        .catch(error => {
            throw error; // Re-throw the error to handle it elsewhere if needed
        });
}



export function getBlogData(permaLink) {
    return axiosInstance.get(`/blogs/${permaLink}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error; // Re-throw the error to handle it elsewhere if needed
        });
}


