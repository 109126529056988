import React from "react";
import logo from "../assets/images/logo.webp";
import useNavigateCustom from "../hooks/useNavigateCustom";
import mastercard from "../assets/images/mastercard.webp";
import applepay from "../assets/images/applepay.webp";
import gpay from "../assets/images/gpay.webp";
import visa from "../assets/images/visa.webp";
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import LazyImage from "./LazyImage";



function Footer() {
  const navigate = useNavigateCustom();
  const { t } = useTranslation(null, { keyPrefix: "footer" })

  const footerLinks = [
    {
      label: t("blogs"),
      link: "blogs"
    }, {
      label: t("tollRoads"),
      link: "toll-roads"
    }, {
      label: t("faqs"),
      link: "faqs"
    }, {
      label: t("buy"),
      link: "checkout"
    }, {
      label: t("aboutUs"),
      link: "about-us"
    }, {
      label: t("tou"),
      link: "terms-of-use"
    }, {
      label: t("contact"),
      link: "contact-us"
    },
    {
      label: t("privacyPol"),
      link: "privacy-policy"
    },


  ];

  const handleLinkClick = (item) => {
    let link = item.link.toLowerCase().replace(/ /g, "-");
    if (link === "buy-a-vignette") link = "checkout";
    navigate(link);
  };





  return (
    <div className="bg-light-bg border-t-4 border-secondary py-2 ">
      <div className="flex flex-col mx-auto pl-8 py-4 lg:p-4 gap-2 w-custom">
        <Link to={"/"} className="h-10 flex items-center gap-4 ">
          <LazyImage src={logo} alt="logo" className="h-full" />
          <div className="border border-gray-700  h-full "></div>
          <div className=" font-medium">Vignettes.ch</div>
        </Link>

        <div className="my-2 sm:flex items-start justify-between">
          <div className="text-md mb-2 max-w-60">
            {t("title")}
          </div>

          <div className="grid grid-cols-2 w-fit gap-x-10">
            {footerLinks.map((item, index) => (
              <div
                key={index}
                className="font-medium text-md underline underline-offset-1 cursor-pointer"
                onClick={() => handleLinkClick(item)}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>

        <div className="my-2 sm:flex items-end justify-between">
          <div>
            <div className="mb-2">Payment means</div>
            <div className="flex gap-4 flex-wrap">
              <LazyImage src={applepay} alt="paymentmeans" className="h-8 w-14" />
              <LazyImage src={gpay} alt="paymentmeans" className="h-8 w-14" />
              <LazyImage src={mastercard} alt="paymentmeans" className="h-8 w-14" />
              <LazyImage src={visa} alt="paymentmeans" className="h-8 w-14" />
            </div>
          </div>

          <div className="mt-2">
            © 2024 vignettes.ch - All rights reserved!
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
