import React from 'react';
import { Helmet } from 'react-helmet';
import { languages } from "../utils/constants";
import { useLocation } from 'react-router-dom';

const MetaTags = ({ title, description }) => {
  const baseUrl = 'https://vignettes.ch'; // Replace with your base URL

  const location = useLocation();
  const path = location.pathname.split("/").slice(2).join('/');

  const hreflangs = languages.map(locale => (
    <link
      rel="alternate"
      href={`${baseUrl}/${locale.value}/${path}`}
      hreflang={locale.value}
      key={locale.value}
    />
  ));

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://vignettes.ch/media/images/logo.png" />
      <meta property="og:url" content={`${baseUrl}/en/${path}`} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={`${baseUrl}/en/${path}`} />
      <meta name="twitter:card" content="summary_large_image" />

      <link rel="canonical" href={`${baseUrl}/en/${path}`} /> 
      {hreflangs}
      <link rel="alternate" href={`${baseUrl}/en/${path}`} hreflang="x-default" /> 
    </Helmet>
  );
}

export default MetaTags;
