import React, { useEffect } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { languages } from "./constants";
import NotFound404 from "../components/404";
import UnderMaintenance from '../components/UnderMaintenance';
import { changeLanguage } from "i18next";
import HomeNew from "../pages/HomeNew"
import TollRoads from "../pages/TollRoads";
import Banner from "../components/homepage/Banner";

const AboutPage = React.lazy(() => import("../pages/AboutPage"));
const CheckoutPage = React.lazy(() => import("../pages/CheckoutPage"));
const ContactUs = React.lazy(() => import("../pages/ContactUs"));
const BlogPage = React.lazy(() => import("../pages/BlogPage"));
const PaymentStatusPage = React.lazy(() => import("../pages/PaymentStatusPage"));
const FAQPage = React.lazy(() => import("../pages/FAQPage"));
const AllBlogsPage = React.lazy(() => import("../pages/AllBlogsPage"));
const TermsOfUsePage = React.lazy(() => import("../pages/TermsOfUsePage"));
const PrivacyPolicyPage = React.lazy(() => import("../pages/PrivacyPolicyPage"));


function LanguageAssigner() {
  const { lng } = useParams();
  const location = useLocation()
  const hasValue = (array, targetValue) => {
    return array.some((obj) => obj.value === targetValue);
  };



  useEffect(() => {
    if (hasValue(languages, lng)) {
      changeLanguage(lng);
      localStorage.setItem("lng", lng);
    }
  }, [lng]);

  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

  if (!hasValue(languages, lng)) return <NotFound404 />; // when no language available for translation


  const isHomepage = location.pathname === `/${lng}` || location.pathname === `/${lng}/`

  return (
    <div>
      {!location.pathname.includes("checkout") && !isHomepage && <Banner />}
      <Routes>

        <Route path="/" element={<HomeNew />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/checkout" element={isMaintenanceMode ? <UnderMaintenance /> : <CheckoutPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blogs" element={<AllBlogsPage />} />
        <Route path="/blogs/*" element={<BlogPage />} />
        <Route path="/order/:status" element={<PaymentStatusPage />} />
        <Route path="/faqs" element={<FAQPage />} />
        <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/toll-roads" element={<TollRoads />} />

        <Route path="/*" element={<NotFound404 />} />
      </Routes>
    </div>
  );
}

export default LanguageAssigner;
