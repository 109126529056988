import englishFlag from "../assets/images/flags/english.webp";
import frenchFlag from "../assets/images/flags/france.webp";
import italianFlag from "../assets/images/flags/italy.webp";
import germanFlag from "../assets/images/flags/germany.webp";
import spanishFlag from "../assets/images/flags/spain.webp";

export const languages = [
  {
    value: "en",
    flag: englishFlag,
    name: "English",
  },
  {
    value: "fr",
    flag: frenchFlag, // Add the path to the French flag
    name: "French",
  },
  {
    value: "it",
    flag: italianFlag, // Add the path to the Italian flag
    name: "Italian",
  },
  {
    value: "de",
    flag: germanFlag, // Add the path to the German flag
    name: "German",
  },
  {
    value: "es",
    flag: spanishFlag, // Add the path to the Spanish flag
    name: "Spanish",
  },
];

export const reactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "30px",
    height: "56px",
    boxShadow: state.isFocused ? null : null,
    padding: "0 8px",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#01A655" : "#fff",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "56px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "56px",
  }),
};


export const vehicles = [
  {
    "product_id": "1",
    "combination_id": "1",
    "name": {
      "en": "Motor Vehicle",
      "fr": "Véhicule à moteur",
      "de": "Motorfahrzeug",
      "it": "Veicolo a motore",
      "es": "Vehículo de motor"
    },
    "subtitle": {
      "en": "includes passenger cars, delivery vans, light articulated lorries, light and heavy work vehicles",
      "fr": "comprend les voitures particulières, les camionnettes de livraison, les semi-remorques légers, les véhicules de travail légers et lourds",
      "de": "umfasst Personenkraftwagen, Lieferwagen, leichte Sattelschlepper sowie leichte und schwere Arbeitsfahrzeuge",
      "it": "comprende autovetture, furgoni per le consegne, autoarticolati leggeri, veicoli da lavoro leggeri e pesanti",
      "es": "Incluye turismos, furgonetas de reparto, camiones articulados ligeros y vehículos de trabajo ligeros y pesados."
    },

    "image_id": "1"
  },
  {
    "product_id": "2",
    "combination_id": "2",
    "name": {
      "en": "Trailers",
      "fr": "Remorques",
      "de": "Anhänger",
      "it": "Rimorchi",
      "es": "Remolques"
    },
    "subtitle": {
      "en": "includes trailers with a gross vehicle weight of up to 3.5t",
      "fr": "comprend les remorques avec un poids total en charge allant jusqu'à 3,5 t",
      "de": "umfasst Anhänger mit einem zulässigen Gesamtgewicht von bis zu 3,5 t",
      "it": "comprende rimorchi con un peso lordo del veicolo fino a 3,5 t",
      "es": "Incluye remolques con un peso bruto vehicular de hasta 3,5 t."
    },

    "image_id": "2"
  },
  {
    "product_id": "3",
    "combination_id": "3",
    "name": {
      "en": "Motorbike",
      "fr": "Moto",
      "de": "Motorrad",
      "it": "Moto",
      "es": "Moto"
    },
    "subtitle": {
      "en": "includes motorcycles, quads and trikes.",
      "fr": "comprende motociclette, quad et trike.",
      "de": "umfasst Motorräder, Quads und Trikes.",
      "it": "comprende motociclette, quad e trike.",
      "es": "Incluye motos, quads y triciclos."
    },

    "image_id": "3"
  }
]

export const europeanUnionCountriesArray = [
  'at', // Austria
  'be', // Belgium
  'bg', // Bulgaria
  'cy', // Cyprus
  'cz', // Czech Republic
  'de', // Germany
  'dk', // Denmark
  'ee', // Estonia
  'es', // Spain
  'fi', // Finland
  'fr', // France
  'gr', // Greece
  'hr', // Croatia
  'hu', // Hungary
  'ie', // Ireland
  'it', // Italy
  'lt', // Lithuania
  'lu', // Luxembourg
  'lv', // Latvia
  'mt', // Malta
  'nl', // Netherlands
  'pl', // Poland
  'pt', // Portugal
  'ro', // Romania
  'se', // Sweden
  'si', // Slovenia
  'sk', // Slovakia
];



export const currencyRates = {
  USD: 1.11,
  INR: 92.45,
  EUR: 1.03,
  CHF: 1,   // taking CHF as base price
  GBP: 0.87
};


// Using Dictionary as react-select component accepts options in Dictionary form 
export const currencyOptions = [
  { value: 'CHF', label: 'CHF' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  { value: 'USD', label: 'USD' },
  { value: 'INR', label: 'INR' }
];