import { useNavigate } from "react-router-dom";

const useNavigateCustom = () => {
  const navigate = useNavigate();

  return (link) => {
    let lng = "en";
    if (localStorage.hasOwnProperty("lng")) lng = localStorage.getItem("lng");
    if (link === "") navigate(`/${lng}`);
    else navigate(`/${lng}/${link}`);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
};

export default useNavigateCustom;
