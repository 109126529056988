import React, { useEffect } from "react";
import logo from "../../assets/images/logo.webp"
import roadLogo from "../../assets/images/roadLogo.webp"
import { useTranslation } from "react-i18next";
import useNavigateCustom from "../../hooks/useNavigateCustom";
import banner from "../../assets/images/bannerAboutUs.webp"

function Banner() {

  const preloadImages = () => {
    const preloadassets = [banner, logo, roadLogo]

    // eslint-disable-next-line array-callback-return
    preloadassets.map((item) => {
      const link = document.createElement('link')
      link.rel = 'preload'
      link.as = 'image'
      link.href = item;
      document.head.appendChild(link)
    })

  }

  useEffect(() => {
    preloadImages()
  }, [])


  const { t } = useTranslation(null, { keyPrefix: "topBanner" });
  const navigate = useNavigateCustom();
  return (
    <div className=" min-h-[40vh] relative flex items-center justify-center">
      <img
        src={banner}
        alt="banner"
        className="w-full h-full object-cover absolute inset-0  z-[-1] "
      />
      <div className="w-custom bg-white flex justify-evenly p-10  gap-10 rounded-lg bg-opacity-70 uppercase m-4">
        <div className="hidden md:flex items-center justify-center">
          <img src={logo} alt="logo" />
        </div>
        <div className="flex flex-col justify-center items-start ">
          <div className="text-2xl md:text-4xl lg:text-5xl font-semibold ">
            {t("bannerHeading")}
          </div>
          <div className=" font-medium mb-3 md:text-xl lg:text-2xl">
            {t("bannerSubHeading")}
          </div>
          <button
            className="button uppercase min-w-32 mt-2"
            onClick={() => navigate("checkout")}
          >
            {t("bannerButton")}
          </button>
        </div>
        <div className="flex max-sm:flex-shrink-0 max-sm:basis-20 flex-grow">
          <img src={roadLogo} alt="raodLogo" className="my-auto w-36 h-26" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
