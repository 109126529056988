import React, { useState } from "react";
import logo from "../assets/images/logo.webp";
import Sidebar from "./Sidebar";
import useWindowResize from "../hooks/useWindowResize";
import NavOptions from "./NavOptions";
import useNavigateCustom from "../hooks/useNavigateCustom";

function Navbar() {
  const [showSidebar, setShowSidebar] = useState(true);
  useWindowResize(setShowSidebar); // for switching between mobile and desktop view of sidebars
  const navigate = useNavigateCustom();
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <div className="h-16 px-8 py-4 flex justify-between border-b-4 border-secondary ">
      <div
        className="h-full flex items-center gap-4 cursor-pointer"
        onClick={() => navigate("")}
      >
        <img src={logo} alt="logo" className="h-7 w-6 " />
        <div className="border border-gray-700  h-full "></div>
        <div className="font-medium">Vignettes.ch</div>
      </div>

      <div
        className="my-auto lg:hidden"
        onClick={() => {
          setShowSidebar((prev) => !prev);
          document.body.classList.toggle("overflow-hidden", true); // making the body overflow hidden when sidebar is show so that user can not scroll
        }}
      >
        <svg
          width="32px"
          height="32px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="hamburger"
          aria-expanded={showSidebar ? "false" : "true"}
        >
          <g id="SVGRepo_iconCarrier">
            <path
              className="line-1"
              d="M20 7L4 7"
              stroke="#000000"
              strokeWidth="1.5"
              strokeLinecap="round"
            ></path>
            <path
              className="line-2"
              d="M20 12L4 12"
              stroke="#000000"
              strokeWidth="1.5"
              strokeLinecap="round"
            ></path>
            <path
              className="line-3"
              d="M20 17L4 17"
              stroke="#000000"
              strokeWidth="1.5"
              strokeLinecap="round"
            ></path>
          </g>
        </svg>
      </div>

      <NavOptions
        hidden={true}
        setShowSidebar={setShowSidebar}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <Sidebar
        setShowSidebar={setShowSidebar}
        showSidebar={showSidebar}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    </div>
  );
}

export default Navbar;
