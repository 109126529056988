import React from 'react'
import { useTranslation } from "react-i18next";
import LazyImage from '../LazyImage';


import listDetails from "../../assets/svgs/listDetails.svg";
import creditCard from "../../assets/svgs/creditCard.svg";
import roadWeather from "../../assets/svgs/roadWeather.svg";
import RightArrow from "../../assets/svgs/rightArrow.svg"

const HowItWorksInfoBoxes = () => {
    const { t } = useTranslation(null, { keyPrefix: "homepage" });

    const boxesData = [
        {
            image: listDetails,
            label: t('howItWorks', { returnObjects: true })[0].label,
            info: t('howItWorks', { returnObjects: true })[0].info
        },
        {
            image: creditCard,
            label: t('howItWorks', { returnObjects: true })[1].label,
            info: t('howItWorks', { returnObjects: true })[1].info
        },
        {
            image: roadWeather,
            label: t('howItWorks', { returnObjects: true })[2].label,
            info: t('howItWorks', { returnObjects: true })[2].info
        },
    ]

    return (
        <div className='w-custom mx-auto py-10'>
            < div className="flex flex-col md:flex-row w-full items-center justify-between bg-white gap-5" >
                <div className=" bg-white flex flex-col items-center justify-center p-2 gap-2 rounded-lg font-bold text-center text-lg">
                    <LazyImage src={boxesData[0].image} alt="howItWorks" className="w-16 h-16" />
                    <p>{boxesData[0].label}</p>
                    <p className=' font-normal text-sm w-1/2 md:w-full'>{boxesData[0].info}</p>
                </div>
                <LazyImage src={RightArrow} className={"m-2 h-10 rotate-90 md:rotate-0"} alt={"Right arrow"} />
                <div className=" bg-white flex flex-col items-center justify-center p-2 gap-2 rounded-lg font-bold text-center text-lg">
                    <LazyImage src={boxesData[1].image} alt="howItWorks" className="w-16 h-16" />
                    <p>{boxesData[1].label}</p>
                    <p className=' font-normal text-sm w-1/2 md:w-full'>{boxesData[1].info}</p>
                </div>
                <LazyImage src={RightArrow} className={"m-2 h-10 rotate-90 md:rotate-0"} alt={"Right arrow"} />
                <div className=" bg-white flex flex-col items-center justify-center p-2 gap-2 rounded-lg font-bold text-center text-lg">
                    <LazyImage src={boxesData[2].image} alt="howItWorks" className="w-16 h-16" />
                    <p>{boxesData[2].label}</p>
                    <p className=' font-normal text-sm w-1/2 md:w-full'>{boxesData[2].info}</p>
                </div>
            </div >
        </div>
    )
}

export default HowItWorksInfoBoxes