import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./utils/i18n";
import FallbackPageLoader from "./components/FallbackPageLoader";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<FallbackPageLoader />}>
    <App />
  </Suspense>
);

