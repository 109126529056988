import React from "react";
import NavOptions from "./NavOptions";
import logo from "../assets/images/logo.webp";
import useNavigateCustom from "../hooks/useNavigateCustom";
function Sidebar({
  showSidebar,
  setShowSidebar,
  setSelectedOption,
  selectedOption,
}) {
  const navigate = useNavigateCustom();



  return (
    <>
      <div
        className={`bg-white lg:hidden flex-shrink-0 p-6 px-8 absolute z-50 top-0 left-0 transform transition-transform duration-300 ${showSidebar ? "-translate-x-full" : "translate-x-0"
          }  h-svh rounded-tr-3xl rounded-br-3xl pr-20`}
      >
        <div
          className="h-8 flex items-center gap-4 cursor-pointer my-6 ml-3"
          onClick={() => {
            // navigate("");
            setShowSidebar((prev) => !prev);
            document.body.classList.toggle("overflow-hidden", false);
          }}
        >
          <img src={logo} alt="logo" className="h-full" />
          <div className="border border-gray-700  h-full "></div>
          <div className="font-medium">Vignettes.ch</div>
        </div>

        <NavOptions
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setShowSidebar={setShowSidebar}
        />
      </div>
      <BgComponenet setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
    </>
  );
}

export default Sidebar;

const BgComponenet = ({ setShowSidebar, showSidebar }) => {
  return (
    <div
      className={`fixed left-0 w-svg w-screen top-0 h-[200vh]  ${showSidebar ? "hidden" : ""
        } bg-black bg-opacity-70 z-40 lg:hidden`}
      onClick={() => {
        setShowSidebar(true);
        document.body.classList.toggle("overflow-hidden", false); // making the body overflow hidden when sidebar is show so that user can not scroll
      }}
    ></div>
  );
};
