// When users hits the / without any language then this component will
// search if lng is present in localstorage, if present then will redirect to /lng page
// otherwise will take english as default

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NavigateToHome() {
  const navigate = useNavigate();
  useEffect(() => {
    const savedLng = localStorage.getItem("lng");
    if (savedLng) navigate(`/${savedLng}`);
    else navigate("/en");
  }, [navigate]);
  return <></>;
}

export default NavigateToHome;
