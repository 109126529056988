import React from "react";
import logo from "../../assets/images/logo.webp"
import roadLogo from "../../assets/images/roadLogo.webp"
import { useTranslation } from "react-i18next";
import useNavigateCustom from "../../hooks/useNavigateCustom";

function BannerNew() {



  const { t } = useTranslation(null, { keyPrefix: "topBanner" });
  const navigate = useNavigateCustom();
  return (
    <div className=" relative flex items-start justify-center px-8 md:px-0 py-5 ">
      <div className="w-custom  flex items-center justify-between rounded-lg bg-opacity-70 uppercase my-5 gap-5">

        {/* BANNER LOGO  */}
        <img src={logo} alt="logo" className=" w-[135px] h-[149px] hidden lg:flex" />
        {/* </div> */}

        {/* BANNER CONTENT  */}
        <div className="flex flex-col justify-center items-start ">
          <div className="text-2xl md:text-3xl lg:text-4xl font-semibold ">
            {t("bannerHeading")}
          </div>
          <div className="font-medium text-base w-5/6 md:font-medium mb-3 md:text-xl lg:text-xl leading-5 md:leading-none">
            {t("bannerSubHeading")}
          </div>
          <button
            className="button uppercase min-w-32 mt-2 md:hidden"
            onClick={() => navigate("checkout")}
          >
            {t("bannerButton")}
          </button>
        </div>

        {/* TOLLS ROAD LOGO  */}
        <div className="flex max-sm:flex-shrink-0 max-sm:basis-20 flex-grow justify-end">
          <img src={roadLogo} alt="raodLogo" className="my-auto w-36 h-26" />
        </div>


      </div>
    </div>
  );
}

export default BannerNew;
