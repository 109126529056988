import React from "react";
import mountainImage from "../../assets/images/homePageInfo.webp";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import LazyImage from "../LazyImage"

function HowItWorks() {
    const { t } = useTranslation(null, { keyPrefix: "homepage.howItWorksSection" });

    return (
        <div className="bg-[#FBFBEA] py-8">

            <div className="w-custom mx-auto max-md:px-6">
                {/* INFO TOP  */}
                <div>
                    <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
                        {t("heading")}
                    </h2>

                    <LazyImage
                        src={mountainImage}
                        alt={"Mountains"}
                        className={"my-4 h-[356px] w-full object-cover rounded-xl"}
                    />


                    <ul className="pb-4 text-lg">
                        {t("paragraphs", { returnObjects: true }).map((content, index) => (
                            <li key={index} className=" py-2">
                                {content}
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default HowItWorks;
